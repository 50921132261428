<template>
    <v-row justify="center">
        <v-dialog value="1" eager persistent width="650">
            <v-card class="page">
                <v-container class="px-13 mx-auto text-center">
                    <h1 class="page-title mt-5">{{ item.name | name }} <span>{{ item.calories | cal }}</span></h1>
                    <div class="page-price">{{ item.price | itemPrice }}</div>
                    <v-img :src="item['image-uri']"  class="mx-auto" max-width="320" eager  height="auto" />
                    <h2 class="item-title my-4" v-if="drinkModifiers.length">{{ translate('choose_size', 'CHOOSE SIZE') }}</h2>
                    <div class="d-flex justify-center" v-if="drinkModifiers.length">
                        <BaseSize v-model="size" :mods="drinkModifiers" />
                    </div>
                   <h2 class="quantity-title my-10">{{ translate('choose_quantity', 'CHOOSE QUANTITY') }}</h2>
                    <div class="quantity d-flex justify-center">
                        <QuantityOption
                            v-model="quantity"
                            width="300"
                            :background="true"
                            :minusColor="$vuetify.theme.themes.light.primary"
                            :plusColor="$vuetify.theme.themes.light.primary"
                            :totalSelected="totalSelected"
                            :strict="false"
                            :size="90" />
                    </div>
                    <v-card-actions class="justify-center card-action mt-15">
                        <v-btn @click="$router.push({name: 'menu.index'})" outlined color="primary" class="btn btn-cancel">{{ toTitleCase(translate('cancel', 'Cancel')) }}</v-btn>
                        <div class="mx-3"></div>
                        <v-btn @click="addToCart" color="primary" elevation="0" class="btn btn-addcart">
                          <svg width="60" height="50" viewBox="0 0 60 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="max-width: 52.74px; width: 100%" :class="{ 'icon-rtl': locale === 'ar-sa' }">
                            <path d="M24.7976 49.0191C27.6948 49.0191 30.0435 46.6704 30.0435 43.7732C30.0435 40.876 27.6948 38.5273 24.7976 38.5273C21.9004 38.5273 19.5518 40.876 19.5518 43.7732C19.5518 46.6704 21.9004 49.0191 24.7976 49.0191Z" fill="white"/>
                            <path d="M45.7809 49.0191C48.6781 49.0191 51.0267 46.6704 51.0267 43.7732C51.0267 40.876 48.6781 38.5273 45.7809 38.5273C42.8837 38.5273 40.535 40.876 40.535 43.7732C40.535 46.6704 42.8837 49.0191 45.7809 49.0191Z" fill="white"/>
                            <path d="M1.94911 3.55532H9.48025C9.57759 3.90586 18.4267 35.7668 17.8663 33.7496C18.0764 34.506 18.7663 35.0303 19.5518 35.0303H51.0267C51.8071 35.0303 52.4936 34.5129 52.7088 33.7615L59.8198 9.28097C59.97 8.75335 59.8642 8.18634 59.5346 7.74755C59.2033 7.3104 58.686 7.05254 58.1378 7.05254H14.0805L12.4942 1.33879C12.2841 0.582338 11.5942 0.0581055 10.8087 0.0581055H1.94911C0.982595 0.0581055 0.2005 0.8402 0.2005 1.80671C0.2005 2.77323 0.982595 3.55532 1.94911 3.55532ZM44.0323 28.0358H26.5462C25.5797 28.0358 24.7976 27.2537 24.7976 26.2872C24.7976 25.3207 25.5797 24.5386 26.5462 24.5386H44.0323C44.9988 24.5386 45.7809 25.3207 45.7809 26.2872C45.7809 27.2537 44.9988 28.0358 44.0323 28.0358ZM23.049 17.5442H47.5295C48.496 17.5442 49.2781 18.3263 49.2781 19.2928C49.2781 20.2593 48.496 21.0414 47.5295 21.0414H23.049C22.0825 21.0414 21.3004 20.2593 21.3004 19.2928C21.3004 18.3263 22.0825 17.5442 23.049 17.5442Z" fill="white"/>
                          </svg> {{ translate('add', 'Add') }}</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import Vue from 'vue';
import itemMixin from '@/mixins/item';
import translationMixin from '@/mixins/translations';
import globalMixin from "../../../mixins/global";
import { mapGetters, mapMutations } from 'vuex'
import QuantityOption from '@/components/base/QuantityOption.vue';
import BaseSize from '@/components/base/BaseSize.vue';

export default {
  name: 'AddItem',
  mixins: [itemMixin, translationMixin, globalMixin],
  components:{
    QuantityOption,
    BaseSize,
  },
  computed: {
    ...mapGetters({
      menus: 'cart/getMenu',
      locale: 'app/getLocale',
    }),
    maximum() {
      if (this.modifiergroup?.maximum) {
        return this.modifiergroup?.maximum;
      }
      return 0;
    },
  },
  data: () => ({
    size: 0,
    quantity: 1,
    item: null,
    drinkSizes: [],
    drinkModifier: null,
    drinkModifiers: [],
    modifiers: [],
    selectedModifiers: [],
    totalSelected: 0,
    bus: new Vue(),
  }),
  created() {
    this.findItem();
  },
  methods: {
    ...mapMutations({
      add: 'cart/add',
    }),
    findItem() {
      this.menus.data.map(menu => {
        if (menu.id === this.$route.params.catId) {
          menu.attributes.items.map(item => {
            if(item.id === Number(this.filterId(this.$route.params.itemId))) {
              this.item = item;
              this.drinkSizes = this.getDrinkSizes();
              console.log('this.drinkSizes: ', this.drinkSizes);
              if (this.drinkSizes.length) {
                this.drinkModifiers = this.filterModifiers(this.drinkSizes[0].modifiers);
                console.log('this.drinkModifiers: ', this.drinkModifiers);
              }
            }
          })
        }
      })
    },
    getDrinkSizes() {
      return this.getModifiers().filter(modgroup => {
        return modgroup.code === 'meal-drink';
      });
    },
    getModifiers() {
      let modgroup_ids = this.item['modifier-groups'].map((modgroup) => {
        return modgroup.id;
      });
      return this.filterModifierGroups(modgroup_ids)
    },
    filterModifierGroups(modgroup_ids) {
      return this.menus.included.modifierGroups.filter(modgroup => {
        return modgroup_ids.includes(modgroup.id);
      }).map(modgroup => {
        return { id: modgroup.id, ...modgroup.attributes };
      });
    },
    filterModifiers(modifier_ids) {
      return this.menus.included.modifiers.filter(modifier => {
        return modifier_ids.includes(modifier.id);
      });
    },
    addToCart() {
      this.selectedModifiers = []
      this.bus.$emit('add-modifiers')
      this.$nextTick(() => {
        var item = {
          id: this.item.id,
          price: this.item.price,
          description: this.item.description,
          quantity: this.quantity,
          notes: "notes",
          name: this.item.name,
          imgUrl: this.item['image-uri'],
          discount: 0,
          'combo-items': [],
          modifiers: this.selectedModifiers,
          ingredients: [],
          total: this.item.price,
          // total: this.item.price * this.quantity
        }
        this.add(item)
        this.$router.push({name:'menu.index', query: { item: this.name(this.item.name) }})
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-rtl {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
    .page{
        &-price{
            font-weight: bold;
            font-size: 48px;
            line-height: 72px;
            color: var(--v-primary-base);
        }
        &-title{
            font-weight: bold;
            font-size: 50px;
            line-height: 75px;
            color: #000000;
            span{
                font-weight: 300;
                font-size: 48px;
                line-height: 1;
            }
        }
        .quantity{
            &-title{
                font-weight: bold;
                font-size: 24px;
                line-height: 54px;
                color: #000000;
            }
        }
        .item{
            &-title{
                font-weight: bold;
                font-size: 24px;
                line-height: 54px;
                color: #000000;
            }

        }

        .card-action{
            .btn{
                border-radius: 20px !important;
                height: 122.75px !important;
                font-weight: bold;
                font-size: 36px;
                &-addcart{
                    width: 268px !important;
                    color: #FFFFFF !important;
                     text-transform: capitalize !important;
                }
                &-cancel{
                    border: 6px solid var(--v-primary-base);
                    text-transform: capitalize !important;
                    width: 230px !important;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
